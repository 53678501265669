import React, { useEffect, useState } from "react";
import { Button, Modal } from "@mantine/core";
import flat from "flat";
import { BeatLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import { loadingStates, validateEmail } from "../../utilities/utilities";
import colors from "../../utilities/design";
import { showNotification } from "@mantine/notifications";
import Editor from "../../components/Editor";
import TemplatesList from "../../components/TemplateManager/TemplatesList";
import { getTemplateEditorField } from "../../utilities/apis/templates";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import { selectUserData } from "../../redux/selectors";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  createEditorField,
  getEditorField,
} from "../../utilities/apis/agreements";
import TaskNewForm from "../../components/CaseNewUI/TaskNewForm";
import { selectAgreementWorkFlow } from "../../redux/selectors";
import { agreementTranSaveApi } from "../../utilities/apis/agreements";

const AgreementForm4 = ({ id, form, setUiConfigs, uiConfigs, save }) => {
  const agreementFormJson = flat.unflatten(form.values);
  const slctAgreementWorkFlow = useSelector(selectAgreementWorkFlow) || [];
  console.log("Form values after user involved:", form.values);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentUser = useSelector(selectUserData) || {};

  const [modal, setModal] = useState(false);

  const [configs, setConfigs] = useState({
    templateView: false,
    editorKey: "key-1",
    templateId: null,
    editorContent: agreementFormJson.contractQuillJsDelta || {
      ops: [{ insert: "Agreement content goes here..." }],
    },
    templateField: "",
  });
  const [tasksConfig, setTasksConfig] = useState({
    reviweFormVisible: false,
    taskFormVisible: false,
    loading: loadingStates.NO_ACTIVE_REQUEST,
    tasks: [],
    page: 1,
    tasksCount: null,
    loadTask: null,
    loadRemark: null,
    completionFlagBtnLoading: false,
  });

  const [selectedFile, handleFileChange] = useState(null);
  console.log("selected file:", selectedFile);
  const getTemplateField = async (templateId) => {
    const formData = {
      templateId,
    };
    const response = await apiWrapWithErrorWithData(
      getTemplateEditorField(formData)
    );
    if (response.success) {
      setConfigs((prevState) => ({
        ...prevState,
        templateField: response?.getFieldName?.fieldName,
      }));
      console.log(response?.getFieldName?.fieldName);
      saveAgreementInputField(response?.getFieldName?.fieldName);
    } else {
      // showNotification({
      //   color: "red",
      //   title: "Editor Field",
      //   message: "Something went wrong.",
      // });
    }
  };

  const saveAgreementInputField = async (inputField) => {
    const formData = {
      fieldName: [...new Set(inputField)],
      agreementId: id,
    };
    const response = await apiWrapWithErrorWithData(
      createEditorField(formData)
    );
    if (response?.success) {
      console.log(response.success);
    } else {
      showNotification({
        message: "Error in saving Editor Field.",
        title: "Editor Field",
        color: "red",
      });
    }
  };

  const savePrev = async () => {
    setUiConfigs({
      ...uiConfigs,
      currentStep: 3,
    });
  };

  const saveNext = async () => {
    let cntt = document.querySelector(".ql-editor").innerHTML; //.replace(/"/g, '&#34;')
    await save({
      ...form.values,
      contractQuillJsDelta: configs.editorContent,
      //contractQuillJsDelta:{
      //  ops: [{ insert: cntt }],
      //},
      formCompleted: true,
    });
    navigate("/app/agreements_manager/agreements/details/" + id);
  };

  useEffect(() => {
    if (configs.templateId) {
      getTemplateField(configs.templateId);
    }
  }, [configs.templateId]);
  const handleSaveDisplay = () => {
    var crntStatusCheck2 = false;

    slctAgreementWorkFlow?.map((ele) => {
      if (
        ele.crntStatus === "draft" &&
        (currentUser.loginAs.id === 3 || currentUser.loginAs.id === 4)
      ) {
        //  console.log("inside");
        //  console.log(currentUser.loginAs.id);
        //  console.log(ele.crntStatus);
        crntStatusCheck2 = true;
      }
    });
    return crntStatusCheck2;
  };
  const handleSendForReviewDisplay = () => {
    var crntStatusCheck2 = false;

    slctAgreementWorkFlow?.map((ele) => {
      if (
        ele.crntStatus === "send_to_reviewer" &&
        (currentUser.loginAs.id === 3 || currentUser.loginAs.id === 4)
      ) {
        // console.log("inside");
        // console.log(currentUser.loginAs.id);
        // console.log(ele.crntStatus);
        crntStatusCheck2 = true;
      }
    });
    return crntStatusCheck2;
  };
  const handleSendForApprovalDisplay = () => {
    var crntStatusCheck2 = false;

    slctAgreementWorkFlow?.map((ele) => {
      if (
        ele.crntStatus === "send_to_approver" &&
        currentUser.loginAs.id === 4
      ) {
        // console.log("inside");
        // console.log(currentUser.loginAs.id);
        // console.log(ele.crntStatus);
        crntStatusCheck2 = true;
      }
    });
    return crntStatusCheck2;
  };
  const handleSendForSignatureDisplay = () => {
    var crntStatusCheck2 = false;

    slctAgreementWorkFlow?.map((ele) => {
      if (
        ele.crntStatus === "send_to_signauth" &&
        currentUser.loginAs.id === 5
      ) {
        //  console.log("inside");
        //  console.log(currentUser.loginAs.id);
        //  console.log(ele.crntStatus);
        //  flowId = ele.id
        crntStatusCheck2 = true;
      }
    });
    return crntStatusCheck2;
  };

  const handleButtonClickSave = async () => {
    console.log("here");
    var flow_id = null;

    slctAgreementWorkFlow?.map((ele) => {
      if (
        ele.crntStatus === "draft" &&
        (currentUser.loginAs.id === 3 || currentUser.loginAs.id === 4)
      ) {
        flow_id = ele.id;
      }
    });

    const agreementTran = { flowId: flow_id, agreementId: id };

    console.log(agreementTran);

    const { data } = await agreementTranSaveApi(agreementTran);
    console.log("Response", data);
    if (data?.success) {
      showNotification({
        title: "Success",
        message: "Agreement data saved successfully",
        color: "green",
      });
    } else {
      showNotification({
        title: "Error",
        message: "Failed to save agreement data",
        color: "red",
      });
    }
  };

  return (
    <>
      {configs.templateView && (
        <Modal
          overflow="inside"
          opened
          onClose={() => {
            setConfigs((prevState) => ({
              ...prevState,
              templateView: false,
            }));
          }}
          size="calc(80vw)"
        >
          <TemplatesList
            showNewTemplateBtn={false}
            templateType="Agreements"
            useTemplateFunc={(template) => {
              console.log("data of template", {
                template,
              });
              setConfigs((prevState) => ({
                ...prevState,
                templateView: false,
                editorContent: template.quillDelta,
                templateId: template.id,
                editorKey: "key-" + Math.random(),
              }));
            }}
          />
        </Modal>
      )}
      {tasksConfig.taskFormVisible && (
        <Modal
          closeOnClickOutside={false}
          overflow="inside"
          opened
          onClose={() => {
            setTasksConfig({
              ...tasksConfig,
              taskFormVisible: false,
            });
          }}
          size="calc(80vw)"
        >
          <TaskNewForm
            onModalExit={() => {
              setTasksConfig({
                ...tasksConfig,
                taskFormVisible: false,
              });
            }}
          />
        </Modal>
      )}
      <div className="w-full flex flex-col my-4">
        <div className="flex my-4 justify-end">
          <Button
            onClick={() => {
              setConfigs((prevState) => ({
                ...prevState,
                templateView: true,
              }));
            }}
            style={{
              backgroundColor: "#46BDE1",
            }}
          >
            Choose from template
          </Button>
        </div>
        <div className="flex flex-col justify-center mt-4">
          <Editor
            key={configs.editorKey}
            content={configs.editorContent}
            agreementId={id}
            templateId={configs.templateId}
            onContentChange={(content) => {
              setConfigs((prevState) => ({
                ...prevState,
                editorContent: content,
              }));
            }}

          />
          <div className="flex flex-row justify-start gap-4">
            {/* {currentUser.loginAs.id === 3 ||
                     currentUser.loginAs.id === 4 ||
                     currentUser.loginAs.id === 2 ? ( */}
            <>
              {/* <Button
                className="max-w-xs mt-12"
                onClick={handleButtonClickSave}
                style={{
                  backgroundColor: "#F18D05",
                  display: handleSaveDisplay() ? "block" : "none",
                }}
              >
                Save
              </Button> */}
              {/* <Button
                className="max-w-xs mt-12"
                // onClick={() => handleButtonClick("Send for Review")}
                onClick={handleButtonClickSendForReview}
                style={{
                  backgroundColor: "#F18D05", // Different color
                  display: handleSendForReviewDisplay() ? "block" : "none",
                }}
              >
                Submit
              </Button> */}
            </>

            {/* <Button
              className="max-w-xs mt-12"
              onClick={() => handleButtonClickSendForApprove}
              style={{
                backgroundColor: "#F18D05",
                display: handleSendForApprovalDisplay() ? "block" : "none", // Different color
              }}
            >
              Send for Approval
            </Button>

            <Button
              className="max-w-xs mt-12"
              onClick={() => handleButtonClickSendForSignAuth}
              style={{
                backgroundColor: "#F18D05",
                display: handleSendForSignatureDisplay() ? "block" : "none", // Different color
              }}
            >
              Send for Signature
            </Button> */}
            {/* <Button
              onClick={() =>
                setTasksConfig({
                  ...tasksConfig,
                  taskFormVisible: true,
                })
              }
              className="max-w-xs mt-12 ml-12"
              style={{
                backgroundColor: "#2ECC71",
              }}
            >
              Add Task
            </Button> */}
          </div>
        </div>

        {modal && (
          <Modal
            overflow="inside"
            opened
            onClose={() => {
              setTasksConfig({
                ...tasksConfig,
                loadTask: null,
              });
            }}
            size="calc(80vw)"
          >
            {/* <TaskDetailsUI
             title={title}
             parent={parent}
             parentId={parentId}
             taskId={tasksConfig.loadTask}
             onModalExit={() => {
               setTasksConfig({
                 ...tasksConfig,
                 loadTask: null,
               });
             }}
           /> */}
          </Modal>
        )}

        <div className="flex flex-row justify-between">
          {uiConfigs.loading === loadingStates.LOADING ? (
            <BeatLoader color={colors.primary} size={10} />
          ) : (
            <>
              <Button
                className="max-w-xs mt-20"
                onClick={savePrev}
                style={{
                  backgroundColor: "#46BDE1",
                }}
              >
                Previous
              </Button>
              <Button
                className="max-w-xs mt-20"
                onClick={saveNext}
                style={{
                  backgroundColor: "#46BDE1",
                }}
              >
                Finish
              </Button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default AgreementForm4;
