/** @format */

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { showNotification } from "@mantine/notifications";
import {
  ActionIcon,
  Badge,
  Button,
  Pagination,
  Select,
  Skeleton,
  Table,
  Text,
  TextInput,
  Anchor,
  Modal,
} from "@mantine/core";
import { BeatLoader } from "react-spinners";
import { DatePicker } from "@mantine/dates";
import { Eye, Search } from "tabler-icons-react";
import { formatISO } from "date-fns";
import { useDebouncedValue } from "@mantine/hooks";
import { deleteAgreementBulk, getAgreementAuditLog } from "../../utilities/apis/agreements";
import {
  getValueForInput,
  loadingStates,
  csvDownload,
  formatDate,
} from "../../utilities/utilities";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import {
  getAgreementsList,
  getAgreementTypesApi,
  getAgreementsCsvDownload,
} from "../../utilities/apis/agreements";

import Styles from "./AgreementsListingPage.module.css";



const AgreementAuditLogModal =  (agreementId) => {
    

   
  const [open, setOpen] = useState(false);
   const [auditLogData, setAuditLogData] = useState(null);

   useEffect(() => {
     const fetchAuditLog = async () => {
       try {
         const response = await apiWrapWithErrorWithData(
           getAgreementAuditLog(agreementId)
         );
         console.log("Audit Log Response:", response);
         if (response.success) {
           setAuditLogData(response.auditLogs);
         }
       } catch (error) {
         console.error("Error fetching audit log:", error);
       }
     };

     fetchAuditLog();
   }, [agreementId]);
  

 
  return (
    <>
      <div className="flex flex-col">
        <div className="flex justify-between my-4">
          <Text>Agreement Audit Log</Text>
        </div>
      </div>

      <br />

      {!auditLogData && (
        <div className="flex flex-col">
          {Array.from({ length: 10 }).map((i) => (
            <Skeleton key={i} height={30} radius="md" className="my-1 w-full" />
          ))}
          <div className="flex flex-row justify-center">
            <Skeleton height={40} radius="md" className="w-40" />
          </div>
        </div>
      )}
      {auditLogData && (
        <div className="flex flex-col">
          <Table striped>
            <thead>
              <tr>
                <th>Sr No.</th>
                <th>Last Action User</th>
                <th>Last Action User Role</th>
                <th>Pending With</th>
                <th>Remark</th>
                <th>Info</th>
                <th>Time Stamp</th>
              </tr>
            </thead>
            <tbody>
              {auditLogData.map((log, index) => (
                <tr key={log.id}>
                  <td>{index + 1}</td>
                  <td>{log.lastActionUser}</td>
                  <td>{log.lastActionUserRole}</td>
                  <td>{log.pendingWith}</td>
                  <td>{log.remark || "N/A"}</td>
                  <td>{log.action || ""}</td>
                  <td>{log.timeStamp}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}
    
     
    </>
  );
};

export default AgreementAuditLogModal;
