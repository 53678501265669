import { Skeleton } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import { getTotalAgreementsList } from "../../utilities/apis/agreements";
import { loadingStates } from "../../utilities/utilities";

function AgreementGraph1({configs}) {
  
  return (
    <div style={{ width: "50%" }} className="pl-2">
      <div
        className="border-solid border-gray-100 border-2 rounded-lg mt-4"
        style={{
          border: "none",
          boxShadow: "0px 7px 29px 0px rgba(100, 100, 111, 0.1)",
          width: "100%",
          padding: "2%",
        }}
      >
        <div className="mt-2 ml-4">Y-o-Y</div>
        {configs.loading === loadingStates.LOADING && (
          <Skeleton style={{ height: "200px" }} className="mt-4 mx-8" visible />
        )}
        {configs.loading === loadingStates.NO_ACTIVE_REQUEST &&
          configs.data.agreementByYear && (
            <div
              className="w-full"
              style={{
                height: "200px",
                width: "100%",
              }}
            >
              <Bar
                data={{
                  labels: configs.data.agreementByYear.map(
                    (el) =>
                      ` ${el.year}`
                  ),
                  datasets: [
                    {
                      label: "Agreements",
                      backgroundColor: "#804674",
                      barThickness: 30,
                      barPercentage: 0.5,
                      data: configs.data.agreementByYear.map(
                        (el) => el["_count"].id
                      ),
                    },
                  ],
                }}
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      position: "top",
                      display: false,
                    },
                  },
                  scales: {
                    y: {
                      beginAtZero: true,
                      title: {
                        display: true,
                        text: "Agreements",
                      },
                    },
                    x: {
                      beginAtZero: true,
                      title: {
                        display: true,
                        // text: 'No. of Agreement Completed Month wise',
                        text: "Year",
                      },
                    },
                  },
                }}
              />
            </div>
          )}
      </div>
    </div>
  );
}

export default AgreementGraph1;
