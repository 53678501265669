/** @format */

import React, { useState, useEffect } from "react";
import {
  Text,
  Textarea,
  Select,
  TextInput,
  SegmentedControl,
} from "@mantine/core";
import { camelCaseToTitle } from "../../utilities/utilities";
import {apiWrapWithErrorWithData} from "../../utilities/apiHelpers";
import {businessUnitDetails, getOrgDetails} from "../../utilities/apis/agreements";
import flat from "flat";

const AgreementParty = ({
  agreementFormJson,
  partyName,
  dropDownNames,
  setDropDownNames,
  form,
  setName
}) => {
  if(!agreementFormJson[partyName].type){
    agreementFormJson[partyName].type="Entity";
  }
  const [budetails, setBudetails] = useState(null);

  const bu = form.values['businessUnit'];
  console.log("buid: ", bu);
  const budetailsfetch = async () =>{
    const response = await apiWrapWithErrorWithData(businessUnitDetails({businessUnitId: bu}));
    // console.log(response);
    if (response.success){
        setBudetails(response.details)
    } else {
      console.log("error accured in fetching bu details");
    }
  }

  useEffect(() => {
    budetailsfetch();
  }, [bu]);
  // console.log("budetails: ", budetails);
  console.log("form: ", form.values);
  const type = agreementFormJson[partyName].type || "Entity";
  // console.log("type of sanjf: ", type);

  const [phone, setPhone] = useState("");

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    // Allow only up to 10 digits
    if (value.length <= 10 && /^\d*$/.test(value)) {
      setPhone(value);
      // Update the form state only for a complete 10-digit number
      if (value.length === 10) {
        form.setValue(`${partyName}.phone`, value);
      }
    }
  };
  const [orgDetails, setOrgDetails]=useState({
    address: "",
    name: "",
    entityType: "Company",
  })
  useEffect(()=>{
    const getOrganizationDetails =async ()=>{
      const resp = await apiWrapWithErrorWithData(getOrgDetails());
      if(resp.success){
        setOrgDetails({
          address: resp.organizationDetails.orgData.regAddress,
          entityType: resp.organizationDetails.orgData.entityType,
          name: resp.organizationDetails.orgName,
        })
      }
    };
    getOrganizationDetails();

  }, []);
  useEffect(() => {
  if (partyName === "firstParty" && budetails && orgDetails) {
    form.setValues((prevValues) => {
      const currentValues = flat.unflatten(prevValues);

      // Check if firstParty.name exists
      if (!currentValues.firstParty?.name) {
        return flat({
          ...currentValues,
          firstParty: {
            address: orgDetails.address,
            corporateOfficeAddress: budetails.copAddress,
            email: budetails.signEmail,
            entityType: orgDetails.entityType,
            name: orgDetails.name,
            representative: budetails.poc,
            signatory: budetails.signName,
            type: "Entity",
          },
        });
      }

      return prevValues;
    });
  }
}, [budetails, orgDetails, partyName]);



  // Debugging: Log the current phone state and form state value
  // console.log("Local phone state:", phone);
  // console.log("Form state for phone:", form.values[`${partyName}.phone`]);
  // const [type, setType] = useState("Entity");

  return (
    <div className="flex flex-col">
      <br></br>
      <div className="flex justify-between" style={{ marginTop: "10px" }}>
        <Text color="#46BDE1">{camelCaseToTitle(partyName)}</Text>
        <SegmentedControl
          {...form.getInputProps(`${partyName}.type`)}
          color="cyan"
          data={["Entity", "Individual"]}
        />
      </div>
      <div className="grid grid-cols-3 gap-4">
        {type === "Entity" &&   (
          <>
            <Select
              required
              className="inputCustom "
              label="Entity Type"
              style={{ width: "100%", marginLeft: "0px", lineHeight: "1.55" }}
              data={[
                { value: "Company", label: "Company" },
                { value: "LLP", label: "LLP" },
                { value: "Partnership Firm", label: "Partnership Firm" },
                { value: "Sole Proprietary", label: "Sole Proprietary" },
              ]}
              {...form.getInputProps(`${partyName}.entityType`)}
            />
            {partyName!=="firstParty" ?
            <Select
              required
              searchable
              creatable
              className="inputCustom "
              label="Registered Name"
              // onChange={(e)=> setName(e.target.value)}
              placeholder="Enter Registered Name"
              data={[...dropDownNames]}
              getCreateLabel={(query) => `+ Add ${query}`}
              onCreate={(query) => {
                setDropDownNames([...dropDownNames, query]);
              }}
              {...form.getInputProps(`${partyName}.name`)}
              style={{
                width: "100%",
                marginLeft: "0px",
                margin: "0px",
                padding: "0px",
                lineHeight: "1.55",
              }}
            /> :
                <TextInput
                    required
                    label="Registered Name"
                    placeholder="Enter Registered Name"
                    {...form.getInputProps(
                        `${partyName}.name`
                    )}
                />}

            <TextInput
              required
              label="Authorised Signatory Name"
              placeholder="Enter Authorised Signatory Name"
              {...form.getInputProps(
                 `${partyName}.signatory` 
              )}
            />
            <TextInput
              required
              label="Authorised Signatory Email ID"
              placeholder="Enter Authorised Signatory Email ID"
              {...form.getInputProps(
                `${partyName}.email` 
              )}
            />
            <TextInput
              label="Business POC"
              placeholder="Enter Business POC"
              {...form.getInputProps(
                `${partyName}.representative`
              )}
            />
            <div></div>
            <Textarea
              label="Registered Address"
              placeholder="Enter Registered Address"
              {...form.getInputProps(`${partyName}.address`)}
            />
            <Textarea
              label="Corporate Address"
              placeholder="Enter Corporate Address"
              {...form.getInputProps(`${partyName}.corporateAddress`)}
            />
          </>
        )}
        {type ==="Individual" && (
          <>
            <TextInput
              required
              label="Individual Name"
              placeholder="Enter Individual Name"
              {...form.getInputProps(`${partyName}.indi_name`)}
            />
            <TextInput
              label="Father's Name"
              placeholder="Enter Father's Name"
              {...form.getInputProps(`${partyName}.indi_fatherName`)}
            />
            <Textarea
              label="Residence Address"
              placeholder="Enter Residence Address"
              {...form.getInputProps(`${partyName}.indi_address`)}
            />
            <TextInput
              label="PAN No."
              placeholder="Enter PAN No."
              {...form.getInputProps(`${partyName}.indi_pan`)}
            />
            <TextInput
              required
              label="Email ID"
              placeholder="Enter Email ID"
              {...form.getInputProps(`${partyName}.indi_email`)}
            />
            <TextInput
              label="Phone No."
              placeholder="Enter 10 digit phone no."
              value={phone}
              onChange={handlePhoneChange}
              {...form.getInputProps(`${partyName}.indi_phone`)}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default AgreementParty;
