/** @format */

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { showNotification } from "@mantine/notifications";
import { AiOutlineSearch } from "react-icons/ai";

import {
  ActionIcon,
  Badge,
  Button,
  Input,
  Pagination,
  Select,
  Skeleton,
  Table,
  Text,
  CloseButton,
} from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { Eye } from "tabler-icons-react";
import { format, formatISO } from "date-fns";
import { useDebouncedValue } from "@mantine/hooks";
import {
  getValueForInput,
  loadingStates,
  csvDownload,
  formatDate,
} from "../../utilities/utilities";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import {
  getAgreementInRenewalsList,
  getAgreementTypesApi,
  getAgreementsCsvDownload,
} from "../../utilities/apis/agreements";
import { agreementStatusColors } from "../../utilities/enums";
import Styles from "./AgreementsListingPage.module.css";
import HamburgerMenuAgreements from "../HamburgerMenuAgreements";

const statusOptions = [
  {
    label: "Pending with Company",
    value: "Pending with Company",
  },
  {
    label: "TP",
    value: "TP",
  },
];

const AgreementsExpired = () => {

  const [tabName, setTabName]=useState("renewals");
  const [pageRenewals, setPageRenewals]= useState(1);
  const [pageExpired, setPageExpired]= useState(1);
  const [configRenewals,setConfigRenewals ] = useState({
    loading: loadingStates.LOADING,
    data: null,
  });
  let mainData;
  const [configExpired,setConfigExpired ] = useState({
    loading: loadingStates.LOADING,
    data: null,
  });

  const fetchExpired = async ()=>{
  const response = await apiWrapWithErrorWithData(
    getAgreementInRenewalsList({ page: pageExpired, tabName: "expired" })
  );
  setConfigExpired({
    loading: loadingStates.NO_ACTIVE_REQUEST,
    data: response,
  });
  console.log("expired: ", response);
}
useEffect(() => {
  fetchExpired();
}, [pageExpired])

const fetchRenewals = async ()=>{
  const response = await apiWrapWithErrorWithData(
    getAgreementInRenewalsList({ page: pageRenewals, tabName: "renewals" })
  );
  setConfigRenewals({
    loading: loadingStates.NO_ACTIVE_REQUEST,
    data: response,
  });
  console.log("renewals: ", response);
}
useEffect(() => {
  fetchRenewals();
}, [ pageRenewals])
useEffect(() => {
  if(tabName==="renewals"){
    mainData =  configRenewals;
   }
    else if(tabName ==="expired"){
     mainData = configExpired;
    }
}, [tabName,configExpired, configRenewals ])

if(tabName==="renewals"){
 mainData =  configRenewals;
}
 else if(tabName ==="expired"){
  mainData = configExpired;
 }
 const navigate = useNavigate();
 const onClick=(agreementId)=>{
  navigate(`/app/agreements_manager/agreements/details/${agreementId}`);
 }
 let srNo ;
 if(tabName === 'expired'){
  srNo = (pageExpired-1)*10 +1;
 }
 if(tabName === 'renewals'){
  srNo = (pageRenewals-1)*10 +1;
 }

  return (
    <>
      <div
        className="flex"
        style={{
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "12px",
        }}
      >
        <h1
          style={{
            fontSize: "22px",
            fontWeight: "600",
            letterSpacing: "1px",
            textTransform: "uppercase",
            fontFamily: "Montserrat",
            whiteSpace: "nowrap",
            paddingBottom: "13px",
            position: "relative",
            color: "black",
          }}
        >
          Renewals
        </h1>

        <HamburgerMenuAgreements />
      </div>
      <div className="flex justify-around items-center mt-4 hover:text-co">
        <Button
          className="hover:bg-blue-500 hover:text-white"
          variant="outline"
          color="blue"
          onClick={() =>  setTabName("renewals")}
        >
          Renewals
        </Button>
        <Button
          className="hover:bg-red-500 hover:text-white"
          variant="outline"
          color="red"
          onClick={() =>  setTabName("expired")}
        >
          Expired
        </Button>
      </div>

      <div className="flex flex-col">
        <div className="flex justify-between my-4">
          <Text>
            {tabName==="renewals"
              ? "Agreements Renewals List"
              : "Agreements Expired List"}
          </Text>
          <Button
        className="mx-2 btn btn-success btn-lg float-right"
        // disabled={exportButtonLoading}
        // onClick={handleCsvDownload}
        // color={themeColor(colors.agreement)}
      >
        Export
      </Button>
        </div>
        
      </div>
      

      {/* 
      <div className="flex w-full justify-between items-center">
        <div className="flex flex-col">
          <div className="flex flex-row-sm">
            <div className="flex items-center">
              <Select
                className="mx-2"
                placeholder="Sort"
                value={listing.statusType}
                onChange={(val) => {
                  setListing((prevState) => ({
                    ...prevState,
                    statusType: val,
                  }));
                }}
                data={statusOptions}
              />
              <Input
                icon={<AiOutlineSearch />}
                value={""}
                rightSection={<CloseButton />}
                placeholder="Search"
                className="mx-3"
              />
            </div>
          </div>
        </div>
      </div> */}

      {mainData.loading === loadingStates.LOADING && (
        <div className="flex flex-col">
          {Array.from({ length: 10 }).map((i) => (
            <Skeleton key={i} height={30} radius="md" className="my-1 w-full" />
          ))}
          <div className="flex flex-row justify-center">
            <Skeleton height={40} radius="md" className="w-40" />
          </div>
        </div>
      )}
      {mainData.loading===loadingStates.NO_ACTIVE_REQUEST &&
        Boolean(mainData.data?.agreementsCount) && (
          <div className="flex flex-col">
            <Table striped>
              <thead>
                <tr>
                  <th>Sr No.</th>
                  <th>Title</th>
                  <th>Location</th>
                  <th>Type</th>
                  <th>Second Party</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Ageing</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {mainData.data.agreements
                  .filter(
                    (v) =>
                      v.agreementData.json &&
                      v.agreementData.json.typeOfAgreement
                  )
                  .map((row) => (
                    <tr key={row.id}>
                      <td>{srNo++}</td>
                      <td>{`Agreement-${row.id}`}</td>
                      <td>
                        {row.agreementData?.json?.location
                          ? row.agreementData?.json?.location
                          : row.agreementData?.json?.location}
                      </td>
                      <td>{row.agreementData.json.typeOfAgreement}</td>
                      <td>
                        {row.agreementData?.json?.secondParty?.name || "-"}
                      </td>
                      <td>{row.contractStartDate?.slice(0,10) || "-"}</td>
                      <td> {row.contractEndDate?.slice(0,10) || "-"}</td>
                      <td>{row.ageing || "-"}</td>

                      <td>
                        <div className="flex flex-row mr-4">
                          
                          
                            <Button
                              variant="outline"
                              className="hover:bg-green-500 hover:text-white"
                              color="green"
                              onClick={() => {
                                // Handle renew button click
                                // Add your logic here
                              }}
                              style={{ marginLeft: "8px" }}
                            >
                              Notify!
                            </Button>
                         
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        )}
      {
      mainData.loading !== loadingStates.LOADING &&
      !mainData.data?.agreementsCount ? (
        <div
          className="flex justify-center items-center"
          style={{
            minHeight: "300px",
          }}
        >
          No agreements
        </div>
      ) : null}
      {mainData.data?.agreementsCount ? (
        <div className="flex flex-row justify-center my-4">
          <Pagination
            page={tabName==="renwals"? pageRenewals : pageExpired}
            onChange={(page) => {
              if(tabName==="renewals"){
                setPageRenewals(page);
              } else if (tabName==="expired"){
                setPageExpired(page);
              }
            }}
            total={Math.ceil(mainData.data.agreementsCount / 10)}
          />
        </div>
      ) : null}
    </>
  );
};

export default AgreementsExpired;
