/** @format */

import React, { useEffect, useState } from "react";
import { Button } from "@mantine/core";
import flat from "flat";
import { BeatLoader } from "react-spinners";
import { loadingStates, validateEmail } from "../../utilities/utilities";
import colors from "../../utilities/design";
import { showNotification } from "@mantine/notifications";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import { getPartiesList, getWorkSpaceLists } from "../../utilities/apis/parties";
import AgreementParty from "./AgreementParty";
import userPartyCreateHook from "../../hooks/usePartyCreate";
import { useNavigate } from "react-router-dom";

const AgreementForm3 = ({
  id,
  form,
  setUiConfigs,
  uiConfigs,
  save,
  initialState,
  actionText,
}) => {
  const agreementFormJson = flat.unflatten(form.values);
  console.log({ agreementFormJson });
  const [dropDownNames, setDropDownNames] = useState([]);
  const [parties, setParties] = useState([]);
  const [addParty, setAddParty] = useState(0);
  // const[name, setName]= useState("");

  userPartyCreateHook("firstParty", form, parties, initialState);
  userPartyCreateHook("secondParty", form, parties, initialState);
  userPartyCreateHook("thirdParty", form, parties, initialState);
  userPartyCreateHook("fourthParty", form, parties, initialState);
  userPartyCreateHook("fifthParty", form, parties, initialState);

  const partiesApi = async (name) => {
    const { data: res } = await apiWrapWithErrorWithData(
      getPartiesList({ name })
    );
    setParties(res);
    const names = res.map(({ name: val }) => val);
    console.log("response for parties: ", res);
    setDropDownNames(names);
  };
  console.log(dropDownNames)
  useEffect(() => {
    if (Object.keys(agreementFormJson.fifthParty).length) {
      setAddParty(3);
    } else if (Object.keys(agreementFormJson.fourthParty).length) {
      setAddParty(2);
    } else if (Object.keys(agreementFormJson.thirdParty).length) {
      setAddParty(1);
    }

    partiesApi();
  }, []);
  // console.log(addParty);
  const validate = () => {
    const keys = {};
  
    const validateParty = (party) => {
      if (!party) return;
  
      if (party.type === "Entity" && (!party.name || party.name.length < 3)) {
        keys[`${party}.name`] = "Please enter 3 or more characters.";
      }
  
      if (party.type === "Entity" && (!party.signatory || party.signatory.length < 3)) {
        keys[`${party}.signatory`] = "Please enter 3 or more characters.";
      }
  
      if (party.type === "Entity" && !validateEmail(party.email)) {
        keys[`${party}.email`] = "Please enter correct email.";
      }
  
      if (party.type === "Entity" && (!party.entityType || party.entityType.length < 3)) {
        keys[`${party}.entityType`] = "Please fill Entity Type.";
      }
  
      if (party.type === "Individual" && (!party.indi_name || party.indi_name.length < 3)) {
        keys[`${party}.indi_name`] = "Please enter 3 or more characters.";
      }
  
      if (party.type === "Individual" && !validateEmail(party.indi_email)) {
        keys[`${party}.indi_email`] = "Please enter correct email.";
      }
    };
  
    // let parties = [agreementFormJson.firstParty, agreementFormJson.secondParty];
    const parties = [agreementFormJson.firstParty, agreementFormJson.secondParty];
  
    if (addParty === 1) {
      parties.push(agreementFormJson.thirdParty);
    } 
     if (addParty === 2) {
     
      parties.push( agreementFormJson.fourthParty);
    } 
     if (addParty === 3) {
      parties.push(agreementFormJson.fifthParty);
    }
    // parties =  [...new Set(parties)];
    parties.forEach(validateParty);
  
    if (Object.keys(keys).length !== 0) {
      form.setErrors(keys);
      showNotification({
        color: "red",
        title: "Agreement Form",
        message: "Please check all fields are filled properly.",
      });
    }
  
    return Object.keys(keys).length === 0;
  };
  


  const savePrev = async () => {

    setUiConfigs({
      ...uiConfigs,
      currentStep: 1,
    });
  };
  const navigate = useNavigate();
  const saveNext = async () => {

    if (validate()) {
      if (actionText === "sent_to_doc_locker") {
        await save({ ...form.values, formCompleted: true });
        navigate("/app/agreements_manager/agreements/details/" + id);
      }else {
        await save({ ...form.values, formCompleted: true });
        setUiConfigs({
          ...uiConfigs,
          currentStep: 3,
        });
      }
    }
  };


  return (
    <div className="w-full flex flex-col my-4">
      <div className="flex flex-col justify-between my-2">
        <AgreementParty
          agreementFormJson={agreementFormJson}
          dropDownNames={dropDownNames}
          form={form}
          partyName={"firstParty"}
          setDropDownNames={setDropDownNames}
        // setName={setName}
        />

        <AgreementParty
          agreementFormJson={agreementFormJson}
          dropDownNames={dropDownNames}
          form={form}
          partyName={"secondParty"}
          setDropDownNames={setDropDownNames}
        // setName={setName}
        />
        {[1, 2, 3].includes(addParty) ? (
          <AgreementParty
            agreementFormJson={agreementFormJson}
            dropDownNames={dropDownNames}
            form={form}
            partyName={"thirdParty"}
            setDropDownNames={setDropDownNames}
          />
        ) : null}
        {[2, 3].includes(addParty) ? (
          <AgreementParty
            agreementFormJson={agreementFormJson}
            dropDownNames={dropDownNames}
            form={form}
            partyName={"fourthParty"}
            setDropDownNames={setDropDownNames}
          />
        ) : null}
        {[3].includes(addParty) ? (
          <AgreementParty
            agreementFormJson={agreementFormJson}
            dropDownNames={dropDownNames}
            form={form}
            partyName={"fifthParty"}
            setDropDownNames={setDropDownNames}
          />
        ) : null}
      </div>
      <div className="flex justify-end">
        {addParty < 3 ? (
          <Button onClick={() => setAddParty((pre) => pre + 1)}>
            Add Party
          </Button>
        ) : null}
      </div>
      <div className="flex flex-row justify-between">
        {uiConfigs.loading === loadingStates.LOADING ? (
          <BeatLoader color={colors.primary} size={10} />
        ) : (
          <>
            <Button
              className="max-w-xs mt-20"
              onClick={savePrev}
              style={{
                backgroundColor: "#46BDE1",
              }}
            >
              Previous
            </Button>
            <Button
              className="max-w-xs mt-20"
              onClick={saveNext}
              style={{
                backgroundColor: "#46BDE1",
              }}
            >
              {actionText==="sent_to_doc_locker"? "Update": "Next"}
            </Button>
          </>
        )}
      </div>
    </div>
  );
};

export default AgreementForm3;
