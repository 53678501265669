/** @format */

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { showNotification } from "@mantine/notifications";
import { AiOutlineSearch } from "react-icons/ai";

import {
  ActionIcon,
  Badge,
  Button,
  Input,
  Pagination,
  Select,
  Skeleton,
  Table,
  Text,
  CloseButton,
  TextInput,
  Anchor,
  Modal,
} from "@mantine/core";
import { BeatLoader } from "react-spinners";
import { DatePicker } from "@mantine/dates";
import { Eye, Search } from "tabler-icons-react";
import { formatISO } from "date-fns";
import { useDebouncedValue } from "@mantine/hooks";
import { deleteAgreementBulk, getAgreementsSigningCsvDownload } from "../../utilities/apis/agreements";
import {
  getValueForInput,
  loadingStates,
  csvDownload,
  formatDate,
} from "../../utilities/utilities";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import {
  getAgreementsList,
  getAgreementTypesApi,
  getAgreementsCsvDownload,
} from "../../utilities/apis/agreements";
import UserAvatarView from "../../components/UserAvatarView";
import DashboardNotifications from "../DashboardNotifications";
import { agreementStatusColors } from "../../utilities/enums";
import AgreementAnalytics from "../../components/AgreementAnalytics/AgreementAnalytics";
import colors, { themeColor } from "../../utilities/design";
import AgreementGraph from "./AgreementGraph";
import Styles from "./AgreementsListingPage.module.css";
import { INDIA_STATES_UNIONS } from "./AgreementForm2";
import HamburgerMenu from "../HamburgerMenuAgreements";
import HamburgerMenuAgreements from "../HamburgerMenuAgreements";
import AgreementAnalytics1 from "../../components/AgreementAnalytics/AgreementAnalytics1";
import { getAgreementsInSigningList} from "../../utilities/apis/agreements";

const statusOptions = [
  {
    label: "Pending with Company",
    value: "Pending with Company",
  },
  {
    label: "TP",
    value: "TP",
  },
];

const estampOption = [
  {
    label: "Yes",
    value: "Yes",
  },
  {
    label: "No",
    value: "No",
  },
];

const AgreementsInSigning = () => {
  const [open, setOpen] = useState(false);
  const [listing, setListing] = useState({
    list: null,
    agreementsCount: null,
    loaded: false,
    sortBy: "createdAt",
    sortDirection: "desc",
    searchString: "",
    statusType: "",
    estamp: null,
    agreementType: null,
    location: null,
    startDate: null,
    endDate: null,
  });
  const [agreementTypeOptions, setAgreementTypeOptions] = useState([]);
  const [uiConfigs, setUiConfigs] = useState({
    loading: loadingStates.NO_ACTIVE_REQUEST,
    page: 1,
  });
  const [debouncedSearchInput] = useDebouncedValue(listing.searchString, 500);
  const [agreementPayload, setAgreementPayload] = useState({
    page: 1,
    sortByOptions: {
      createdAt: 'desc',
    },
    filterOptions: {},
  });
  const [exportButtonLoading, setExportButtonLoading] = useState(false);

  const [selectedIds, setSelectedIds] = useState([]);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const handleCheckboxChange = (id, isChecked) => {
    setSelectedIds((prevSelectedIds) => {
      if (isChecked) {
        return [...prevSelectedIds, id];
      } else {
        return prevSelectedIds.filter((selectedId) => selectedId !== id);
      }
    });
  };

  const deleteHandler = async () => {
    console.log("Deleting agreements with IDs:", selectedIds);
    const agreementIds = selectedIds;
    setDeleteLoading(true);
    const response = await apiWrapWithErrorWithData(
      deleteAgreementBulk({ agreementIds })
    );
    if (response?.success) {
      showNotification({
        color: "green",
        title: "Agreements",
        message: "Agreement deleted successfully.",
      });
      setOpen(false);
      navigate("/app");
    } else {
      showNotification({
        color: "red",
        title: "Agreements",
        message: "Unable to delete agreement",
      });
    }
    setDeleteLoading(false);
  };

  const fetchAgreementsList = async ({ page }) => {
    let filterOptions = {};
    if (listing.startDate && listing.endDate) {
      filterOptions["createdAt"] = {
        gte: formatISO(listing.startDate),
        lte: formatISO(listing.endDate),
      };
    } else if (listing.startDate || listing.endDate) {
      showNotification({
        title: "Agreement notification",
        message: "please select start and end date",
        color: "red",
      });
      return;
    }
    setUiConfigs({
      ...uiConfigs,
      loading: loadingStates.LOADING,
    });
    const payload = {
      page: page ? page : uiConfigs.page,
      sortByOptions: {
        [listing.sortBy]: listing.sortDirection,
      },
    };
    if (listing.agreementType && listing.statusType && listing.estamp) {
      filterOptions = {
        ...filterOptions,
        typeOfAgreement: {
          equals: listing.agreementType,
        },
        status: {
          equals: listing.statusType,
        },
        agreementType: {
          equals: listing.estamp === "Yes" ? "eStamp" : "normal",
        },
      };
    } else if (listing.agreementType || listing.statusType || listing.estamp) {
      if (listing.agreementType) {
        filterOptions["typeOfAgreement"] = {
          equals: listing.agreementType,
        };
      }
      if (listing.statusType) {
        filterOptions["status"] = {
          equals: listing.statusType,
        };
      }
      if (listing.estamp) {
        filterOptions["agreementType"] = {
          equals: listing.estamp === "Yes" ? "eStamp" : "normal",
        };
      }
    }
    if (listing.location) {
      filterOptions["location"] = listing.location;
    }

    payload["filterOptions"] = filterOptions;

    setAgreementPayload(payload);
    const response = await apiWrapWithErrorWithData(
      getAgreementsList({ ...payload })
    );
    if (response?.success && response?.agreements) {
      setListing({
        ...listing,
        list: response?.agreements,
        agreementsCount: response.agreementsCount,
        loaded: true,
      });
    }
    setUiConfigs({
      ...uiConfigs,
      loading: loadingStates.NO_ACTIVE_REQUEST,
    });
  };
  const data = listing?.list;

  useEffect(() => {
    getAgreementTypes();
  }, []);

  useEffect(() => {
    if (listing.agreementType || listing.statusType || listing.estamp) {
      fetchAgreementsList({ page: 1 });
      setUiConfigs((prevState) => ({
        ...prevState,
        page: 1,
      }));
    } else if (listing.estamp) {
      fetchAgreementsList({ page: 1 });
      setUiConfigs((prevState) => ({
        ...prevState,
        page: 1,
      }));
    } else if (listing.startDate && listing.endDate) {
      fetchAgreementsList({ page: 1 });
      setUiConfigs((prevState) => ({
        ...prevState,
        page: 1,
      }));
    } else {
      if (listing.startDate === null && listing.endDate === null) {
        fetchAgreementsList({});
      }
    }
  }, [listing.sortBy, listing.sortDirection]);

  useEffect(() => {
    fetchAgreementsList({});
  }, [uiConfigs.page]);

  const navigate = useNavigate();
  const onViewClick = (agreementId) => {
    // navigate(`/app/agreements/details/${agreementId}`);
    const url =
      process.env.PUBLIC_URL + `/app/agreements_manager/agreements/details/${agreementId}`;
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const getAgreementTypes = async () => {
    const resp = await apiWrapWithErrorWithData(getAgreementTypesApi());
    if (resp?.success && resp?.typesOfAgreement) {
      const uniqueValues = Array.from(new Set([...resp.typesOfAgreement]));
      const temp = uniqueValues.map((item) => ({ label: item, value: item }));
      setAgreementTypeOptions([...agreementTypeOptions, ...temp]);
    }
  };
  const handleCsvDownload = async () => {
    try {
      setExportButtonLoading(true);
      const response = await apiWrapWithErrorWithData(
        getAgreementsSigningCsvDownload({ ...agreementPayload })
      );
      if (response.success) {
        if (response?.agreements.length > 0) {
          const columnHeaders = Object.keys(response.agreements[0]);
          csvDownload({
            data: response.agreements,
            headers: columnHeaders,
            name: "agreement",
          });
        } else {
          showNotification({
            title: "Agreement csv download",
            message: "No agreement available",
            color: "red",
          });
        }
      }
      setExportButtonLoading(false);
    } catch (error) {
      setExportButtonLoading(false);
      showNotification({
        title: "Agreement csv download",
        message: "Could not load agreement csv",
        color: "red",
      });
    }
  };

  const clearHandler = async () => {
    try {
      setUiConfigs({
        ...uiConfigs,
        page: 1,
        loading: loadingStates.LOADING,
      });

      const payload = {
        page: 1,
        sortByOptions: {
          [listing.sortBy]: listing.sortDirection,
        },
        filterOptions: {},
      };

      setAgreementPayload(payload);
      const response = await apiWrapWithErrorWithData(
        getAgreementsList({ ...payload })
      );

      if (response?.success && response?.agreements) {
        setListing({
          ...listing,
          searchString: "",
          agreementType: "",
          statusType: null,
          estamp: "",
          location: null,
          startDate: null,
          endDate: null,
          list: response?.agreements,
          agreementsCount: response.agreementsCount,
          loaded: true,
          estamp: null,
        });
      }

      setUiConfigs({
        ...uiConfigs,
        loading: loadingStates.NO_ACTIVE_REQUEST,
      });
    } catch (error) {
      console.log("Agreement list fetching error: ", error);
    }
  };

  const [reviewAgreementpage, setReviewAgreementpage] = useState(1);
  let serialNumber = (reviewAgreementpage - 1) * 10 + 1;
  const [configs, setConfigs] = useState({
    loading: loadingStates.LOADING,
    data: null,
  });


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiWrapWithErrorWithData(getAgreementsInSigningList({ page: reviewAgreementpage }));          
        if (response) {
          setConfigs({ loading: loadingStates.NO_ACTIVE_REQUEST, data: response });
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
    fetchData();
  }, [reviewAgreementpage]);
  console.log("configs bro: ", configs);
  return (
    <>
      {configs.loading === loadingStates.NO_ACTIVE_REQUEST && (
      <>
      <div
        className="flex"
        style={{
          justifyContent: "space-between",
          alignItems: "center",
          alignItems: "center",
          marginBottom: "12px",
        }}
      >
        <h1
          style={{
            fontSize: "22px",
            fontWeight: "600",
            letterSpacing: "1px",
            textTransform: "uppercase",
            fontFamily: "Montserrat",
            whiteSpace: "nowrap",
            paddingBottom: "13px",
            position: "relative",
            color: "black",
            // margin: "0 auto"
          }}
        >
          Agreements In Signature Status
        </h1>

        <HamburgerMenuAgreements />
      </div>
      <div className="flex flex-row flex-wrap w-full">
        <AgreementAnalytics1  configs={configs}/>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
      <AgreementGraph configs={configs} />
      </div></>)}
      <div className="flex flex-col">
        <div className="flex justify-between my-4">
          <Text>Agreements Signature Status List</Text>
          <Button
                className="mx-2 btn btn-success btn-lg float-right"
                disabled={exportButtonLoading}
                onClick={handleCsvDownload}
                color={themeColor(colors.agreement)}
              >
                Export
              </Button>
        </div>
      </div>

      {/* <div className="flex w-full justify-between items-center">
        <div className="flex flex-col">
          <div className="flex flex-row-sm">
            <div className="flex items-center">
              <Select
                className="mx-2"
                placeholder="Sort"
                value={listing.statusType}
                onChange={(val) => {
                  setListing((prevState) => ({
                    ...prevState,
                    statusType: val,
                  }));
                }}
                data={statusOptions}
              />
              <Input
                icon={<AiOutlineSearch />}
                value={""}
                rightSection={<CloseButton />}
                placeholder="Search"
                className="mx-3"
              />
            </div>
          </div>
        </div>
      </div> */}

      {/* </div> */}
      {/* </div> */}
      {/* </div> */}
      {configs.loading === loadingStates.LOADING && (
        <div className="flex flex-col">
          {Array.from({ length: 10 }).map((i) => (
            <Skeleton key={i} height={30} radius="md" className="my-1 w-full" />
          ))}
          <div className="flex flex-row justify-center">
            <Skeleton height={40} radius="md" className="w-40" />
          </div>
        </div>
      )}
      {configs.loading === loadingStates.NO_ACTIVE_REQUEST &&
        configs.data.agreements && (
          <div className="flex flex-col">
            <Table striped>
              <thead>
                <tr>
                  <th>Sr No.</th>
                  <th>Title</th>
                  <th>Location</th>
                  <th>Type</th>
                  <th>Created By</th>
                  <th>Status</th>
                  <th>Ageing</th>
                </tr>
              </thead>
              <tbody>
                {configs.data.agreements.map((row) => (
                    <tr key={row.id}>
                      <td>{serialNumber++}</td>
                      <td>{`Agreement-${row.id}`}</td>
                      <td>{row.agreementData.json.location}</td>
                      <td>
                        {row.agreementData.json.typeOfAgreement}
                      </td>
                      <td>
                        <UserAvatarView {...row.createdBy} />
                      </td>
                      <td>
                        <Badge
                          color={agreementStatusColors[row.status] || "orange"}
                        >
                          {row.status}
                        </Badge>
                      </td>
                      <td>
                      {row.ageing}
                      </td>

                      
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        )}
      {configs.loading === loadingStates.NO_ACTIVE_REQUEST &&
      !configs.data?.agreements.length ? (
        <div
          className="flex justify-center items-center"
          style={{
            minHeight: "300px",
          }}
        >
          No agreements
        </div>
      ) : null}
      {/* </div> */}
      {configs.loading === loadingStates.NO_ACTIVE_REQUEST ? (
        <div className="flex flex-row justify-center my-4">
          <Pagination
            page={reviewAgreementpage}
            onChange={(page) => {
              setReviewAgreementpage(page);
            }}
            total={Math.ceil(configs.data.agreementsCount / 10)}
          />
        </div>
      ) : null}
      <Modal
        opened={open}
        onClose={() => setOpen(false)}
        size="calc(30vw)"
        style={{ marginTop: "12%" }}
        title="Select Agreement Type"
      >
        <div className={Styles["popup-desig"]}>
          <p
            style={{
              cursor: "pointer",
              border: "2px solid lightblue",
              padding: "8px",
              margin: "0",
              marginBottom: "8px",
            }}
            onClick={() => navigate("/app/agreements/new")}
          >
            Individual Agreement
          </p>
          <p
            style={{
              cursor: "pointer",
              border: "2px solid lightblue",
              padding: "8px",
              margin: "0",
            }}
            onClick={() => navigate("/app/agreements/new-bulk")}
          >
            Bulk Agreement
          </p>
          <div style={{ textAlign: "center", paddingTop: "3%" }}>
            <button
              style={{
                cursor: "pointer",
                background: "red",
                border: "none",
                padding: "8px",
                color: "white",
                margin: "5px 0",
                borderRadius: "10%",
              }}
              onClick={() => setOpen(false)}
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AgreementsInSigning;
