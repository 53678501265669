/** @format */

import React, { useEffect, useState } from "react";
import { ActionIcon, Badge, Table, Text } from "@mantine/core";
import { Eye } from "tabler-icons-react";
import { getRelatedAgreement } from "../../utilities/apis/agreements";
import { formatDate } from "../../utilities/utilities";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";

const RelatedAgreementModal = (agreementId) => {
  const [relatedAgreementData, setRelatedAgreementData] = useState(null);
  const onViewClick = (agreementId) => {
    const url =
      process.env.PUBLIC_URL +
      `/app/agreements_manager/agreements/details/${agreementId}`;
    window.open(url, "_blank", "noopener,noreferrer");
  };
  useEffect(() => {
    const fetchRelatedAgreement = async () => {
      try {
        const response = await apiWrapWithErrorWithData(
          getRelatedAgreement(agreementId)
        );
        console.log("Reelated Agreement Response:", response);
        if (response.success) {
          setRelatedAgreementData(response);
        }
      } catch (error) {
        console.error("Error fetching audit log:", error);
      }
    };

    fetchRelatedAgreement();
  }, [agreementId]);

  return (
    <>
      <div className="flex flex-col">
        <div className="flex justify-between my-4">
          <Text>Related Agreement</Text>
        </div>
      </div>

      <br />

      {relatedAgreementData?.agreements ? (
        <div
          className="flex flex-col"
          style={{
            overflowX: "scroll",
            marginTop: "15px",
            scrollbarWidth: "none",
            msOverflowStyle: "none",
          }}
        >
          <Table striped>
            <thead>
              <tr>
                <th>Reference No.</th>
                <th>Type</th>
                <th>Location</th>
                <th>Status</th>
                <th>Second Party</th>
                <th>Date</th>
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {relatedAgreementData.agreements.map((row) => (
                <tr key={row.id}>
                  <td>{`Agreement-${row.id}`}</td>
                  <td>{row.agreementData.json.typeOfAgreement}</td>
                  <td>
                    {row.agreementData?.json?.location
                      ? row.agreementData?.json?.location
                      : row.agreementData?.json?.location}
                  </td>

                  <td>
                    <Badge color="orange">{row.status}</Badge>
                  </td>

                  <td>{row.agreementData?.json?.secondParty?.name || "-"}</td>
                  <td>{formatDate(row.createdAt)}</td>

                  <td>
                    <div className="flex flex-row mr-4">
                      <ActionIcon
                        onClick={() => {
                          onViewClick(row.id);
                        }}
                        color="white"
                      >
                        <Eye size={24} />
                      </ActionIcon>
                      {/* )} */}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : <h1>No Agreement</h1>}
    </>
  );
};

export default RelatedAgreementModal;
