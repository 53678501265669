import { Skeleton } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { apiWrapWithErrorWithData } from "../../utilities/apiHelpers";
import { getAgreementByStatus } from "../../utilities/apis/agreements";
import { loadingStates } from "../../utilities/utilities";

function AgreementGraph2( {configs}) {
  const groupedData = {
    '3': 0,
    '5': 0,
    '7': 0,
    '10+': 0,
  };
  configs?.data?.agreementsAgeing?.forEach(item => {
    const ageing = parseInt(item.ageing, 10);
  if (ageing <= 3) {
    groupedData['3'] += item.count;
  } else if (ageing > 3 && ageing <= 5) {
    groupedData['5'] += item.count;
  } else if (ageing > 5 && ageing <= 7) {
    groupedData['7'] += item.count;
  } else if (ageing > 7) {
    groupedData['10+'] += item.count;
  }
  });
  const labels = Object.keys(groupedData);
const dataValues = Object.values(groupedData);
  // const [days,setdays] = useState(["5","10","15", "15+"]);
  // console.log("bhai graph k liye: ", configs);
  return (
    <div style={{ width: "47%", margin: "auto" }}>
      <div
        className="border-solid border-gray-100 border-2 rounded-lg mt-4"
        style={{
          border: "none",
          boxShadow: "0px 7px 29px 0px rgba(100, 100, 111, 0.1)",
          width: "100%",
          padding: "2.5%",
        }}
      >
        <div className="ml-4">Ageing</div>
        {configs.loading === loadingStates.LOADING && (
          <Skeleton style={{ height: "200px" }} className="mt-4 mx-8" visible />
        )}
        {configs.loading === loadingStates.NO_ACTIVE_REQUEST &&
          configs.data.agreementsAgeing && (
            <div
              className="w-full"
              style={{
                height: "200px",
                width: "100%",
              }}
            >
              <Bar
                data={{
                  labels: labels,
                  datasets: [
                    {
                      label: "Ageing",
                      backgroundColor: "#804674",
                      barThickness: 30,
                      barPercentage: 0.5,
                      data: dataValues
                    },
                  ],
                }}
              
                options={{
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      position: "top",
                      display: false,
                    },
                  },
                  scales: {
                    y: {
                      beginAtZero: true,
                      title: {
                        display: true,
                        text: "Agreement" ,
                      },
                      ticks: {
                        stepSize: 1, // Ensure this line is correctly placed
                        precision: 0 // This helps in case there are floating point issues
                      },
                    },
                    x: {
                      beginAtZero: true,
                      title: {
                        display: true,
                        // text: 'No. of Agreement Completed Month wise',
                        text: "Days",
                      },
                    },
                  },
                }}
              />
            </div>
          )}
      </div>
    </div>
  );
}

export default AgreementGraph2;